import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'
import { STATUS_FAILED, STATUS_LOADING, STATUS_SUCCEEDED, TOAST_ERROR, TOAST_SUCCESS } from '../../utils/constants';

import { authApi, guestApi } from '../../utils/urls';
import { enqueueSnackbar } from 'notistack'


// LEGACY STEELCOMPAS (REQUEST TOOL)
import SteelCompass from '../../../../legacy/src/js/services/SteelCompass';
// LEGACY
import AuthStore from '../../../../legacy/src/js/stores/Auth';
import httpClient from '../../services/httpClient';
//

// ----------------- Thunks -----------------------------

export const logIn = createAsyncThunk('auth/logIn', async ({ identity, credential }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: { identity, credential },
    method: 'login'
  };

  const res = await httpClient.post(authApi(), body, { getState, dispatch, rejectWithValue, noToken:true });
  return res.data;
});

export const authStatus = createAsyncThunk('auth/authStatus', async (props, { getState, dispatch, rejectWithValue }) => {

  const body = {
    method: 'status'
  };

  const res = await httpClient.post(authApi(), body, { getState, dispatch, rejectWithValue });
  return res.data;

});

export const incarnate = createAsyncThunk('auth/incarnate', async ({ userId }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: { userId },
    method: 'incarnate'
  };

  const res = await httpClient.post(authApi(), body, { getState, dispatch, rejectWithValue });
  return res.data;
});

export const wakeUp = createAsyncThunk('auth/wakeUp', async (_, { getState, dispatch, rejectWithValue }) => {
   const body = {
    method: 'wakeUp'
  };

  const res = await httpClient.post(authApi(), body, { getState, dispatch, rejectWithValue });
  return res.data;
});

export const logOut = createAsyncThunk('auth/logOut', async (_, {getState, dispatch, rejectWithValue}) => {
  const body = {
    method: 'logout'
  };

  const res = await httpClient.post(authApi(), body, { getState, dispatch, rejectWithValue });
  return res.data;
});

// export const registration = createAsyncThunk('auth/register', async (regInfo, { getState }) => {
//   const body = {
//     data: regInfo,
//     method: 'register'
//   };

//   const res = await httpClient.post(authApi(), body, { getState, dispatch, rejectWithValue });

//   // const message = res.isError ? res.errMsg : 'Successfully registered!'
//   // const variant = res.isError ? TOAST_ERROR : TOAST_SUCCESS
//   // enqueueSnackbar(message, { variant })

//   return res.data;
// });

//registration and login for totally new accounts
export const registrationAndLogin = createAsyncThunk('auth/registrationAndLogin', async (regInfo, { getState, dispatch, rejectWithValue }) => {
  const body = {
    method: 'logister',
    data: regInfo
  };

  const res = await httpClient.post(authApi(), body, { getState, dispatch, rejectWithValue, noToken: true });
  return res.data
});

export const getGuestInfo = createAsyncThunk('auth/getGuestInfo', async ({ id }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    method: 'getGuestInfo',
    data: { id }
  };

  const res = await httpClient.post(guestApi(), body, { getState, dispatch, rejectWithValue, noToken: true });
  return res.data
});

//used when there is already email info in database so we just need to update it with more detail like it happens with guest buy
export const guestRegister = createAsyncThunk('auth/guestRegister', async (regInfo, { getState, dispatch, rejectWithValue }) => {
  const body = {
    method: 'guestRegister',
    data: regInfo
  };

  const res = await httpClient.post(authApi(), body, { getState, dispatch, rejectWithValue, noToken: true });
  return res.data
});

export const hashLogin = createAsyncThunk('auth/loginCdk', async ({ c2hash }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    method: 'loginCdk',
    data: {
      hash: c2hash
    }
  };

  const res = await httpClient.post(authApi(), body, { getState, noToken: true });
  return res.data
});

export const requestNewPassword = createAsyncThunk('auth/requestNewPassword ', async ({ email }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {
      callbackUrl: 'https://app.coursio.com/restore-password/',
      email,
    },
    method: 'requestNewPassword'
  }

  const res = await httpClient.post(authApi(), body, { getState, dispatch, rejectWithValue, noToken: true });

  return res.data;
});

export const restorePassword = createAsyncThunk('auth/restorePassword ', async (data, { getState, dispatch, rejectWithValue }) => {
  const body = {
    method: 'restorePassword',
    data
  };

  const res = await httpClient.post(authApi(), body, { getState, dispatch, rejectWithValue, noToken: true });
  return res.data
});

export const loginGoogle = createAsyncThunk('auth/loginGoogle ', async (jwtToken, { getState, dispatch, rejectWithValue }) => {

  const body = {
    method: 'loginGoogle',
    data:{ idToken: jwtToken }
  };

  const res = await httpClient.post(authApi(), body, { getState, dispatch, rejectWithValue, noToken: true });
  return res.data
});


// ----------------- Reducers -----------------------------

const initialState = {
  userInfo: null,
  loggedIn: false,
  incarnated: false,
  status: 'idle',
  guesInfo: null,
  guesInfoStatus: 'idle'

}

const authSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setLoggedIn(state, action) {
      state.userInfo = action.payload
      state.loggedIn = !!action.payload.sessionId
    },
    setIsIncarnated(state, action) {
      state.incarnated = action.payload
    },
  },
  extraReducers: builder => {
    builder
      // ---- LOG IN -----
      .addCase(logIn.pending, (state, action) => {
        state.status = STATUS_LOADING
        console.log('STATUS LOG IN - LOADING')
      })
      .addCase(logIn.rejected, (state, action) => {
        state.status = STATUS_FAILED
        console.log('STATUS LOG IN - FAILED')
        enqueueSnackbar(action.payload.response.errMsg, { variant: TOAST_ERROR })
      })
      .addCase(logIn.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED
        console.log('STATUS LOG IN - SUCCEEDED')
        const { user, sessionId, roles } = action.payload
        state.loggedIn = !!user
        state.userInfo = action.payload;
        localStorage.setItem('token', sessionId);
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('roles', JSON.stringify(roles));
        // update AUTH state for legacy
        AuthStore.loginRestored(action.payload);
         // SET AUTH TOKEN FOR LEGACY
        SteelCompass.setAuthToken(sessionId);
        enqueueSnackbar('You are now logged in', { variant: TOAST_SUCCESS })
      })
      .addCase(getGuestInfo.pending, (state, action) => {
        state.guestInfoStatus = STATUS_LOADING
      })
      .addCase(getGuestInfo.rejected, (state, action) => {
        state.guestInfoStatus = STATUS_FAILED
        // state.guesInfo = action.payload
        enqueueSnackbar(action.payload.response.errMsg, { variant: TOAST_ERROR });
      })
      .addCase(getGuestInfo.fulfilled, (state, action) => {
        state.guestInfoStatus = STATUS_SUCCEEDED
        state.guesInfo = action.payload
      })
      .addCase(guestRegister.rejected, (state, action) => {
        state.status = STATUS_FAILED
        enqueueSnackbar(action.payload.response.errMsg, { variant: TOAST_ERROR });
      })
      .addCase(registrationAndLogin.rejected, (state, action) => {
        state.status = STATUS_FAILED
        enqueueSnackbar(action.payload.response.errMsg, { variant: TOAST_ERROR });
      })
      .addCase(registrationAndLogin.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED
        state.loggedIn = !!action.payload?.user;
        state.userInfo = action.payload;
        localStorage.setItem('token', action.payload.sessionId);
        localStorage.setItem('user', JSON.stringify(action.payload.user));
        localStorage.setItem('roles', JSON.stringify(action.payload.roles));
        // SET AUTH TOKEN FOR LEGACY
        SteelCompass.setAuthToken(action.payload.sessionId);
        // update AUTH state for legacy
        AuthStore.loginRestored(action.payload)
        enqueueSnackbar('Account was successfully finalized', { variant: TOAST_SUCCESS });
      })
      .addCase(hashLogin.fulfilled, (state, action) => {
        console.log('STATUS LOG IN - SUCCEEDED')
        state.status = STATUS_SUCCEEDED
        state.loggedIn = !!action.payload?.user;
        state.userInfo = action.payload;
        localStorage.setItem('token', action.payload.sessionId);
        localStorage.setItem('user', JSON.stringify(action.payload.user));
        localStorage.setItem('roles', JSON.stringify(action.payload.roles));
        // SET AUTH TOKEN FOR LEGACY
        SteelCompass.setAuthToken(action.payload.sessionId);
        // update AUTH state for legacy
        AuthStore.loginRestored(action.payload)
      })
      .addCase(guestRegister.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED
        state.loggedIn = !!action.payload?.user;
        state.userInfo = action.payload;
        localStorage.setItem('token', action.payload.sessionId);
        localStorage.setItem('user', JSON.stringify(action.payload.user));
        localStorage.setItem('roles', JSON.stringify(action.payload.roles));
        // SET AUTH TOKEN FOR LEGACY
        SteelCompass.setAuthToken(action.payload.sessionId);
        // update AUTH state for legacy
        AuthStore.loginRestored(action.payload)
        enqueueSnackbar('Account was successfully finalized', { variant: TOAST_SUCCESS });
      })
      // ---- LOG OUT -----
      .addCase(logOut.pending, (state, action) => {
        state.status = STATUS_LOADING
        console.log('STATUS LOG OUT - LOADING')
      })
      .addCase(logOut.rejected, (state, action) => {
        state.status = STATUS_FAILED
        console.log('STATUS LOG OUT - FAILED')
      })
      .addCase(logOut.fulfilled, (state) => {
        state.status = STATUS_SUCCEEDED
        console.log('STATUS LOG OUT - SUCCEEDED')
        state.loggedIn = false;
        state.userInfo = null;
        state.incarnated = false;

        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('roles');
        // // SET AUTH TOKEN NULL FOR LEGACY
        SteelCompass.setAuthToken(null);
      })
      // ---- INCARNATE -----
      .addCase(incarnate.pending, (state, action) => {
        state.status = STATUS_LOADING
        console.log('STATUS INCARNATE - LOADING')
      })
      .addCase(incarnate.rejected, (state, action) => {
        state.status = STATUS_FAILED
        console.log('STATUS INCARNATE - FAILED')

        enqueueSnackbar(action.payload.response.errMsg, { variant:TOAST_ERROR })
      })
      .addCase(incarnate.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED
        console.log('STATUS INCARNATE - SUCCEEDED')
        const { user, sessionId, roles } = action.payload

        state.loggedIn = !!user;
        state.incarnated = true;
        state.userInfo = action.payload;
        // update AUTH state for legacy
        AuthStore.loginRestored(action.payload)

        user.incarnated = true;
        localStorage.setItem('token', sessionId);
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('roles', JSON.stringify(roles));

        enqueueSnackbar('Incarnated with success', { variant:TOAST_SUCCESS })

      })
      // ---- WAKEUP -----
      .addCase(wakeUp.pending, (state, action) => {
        state.status = STATUS_LOADING
        console.log('STATUS WAKEUP - LOADING')
      })
      .addCase(wakeUp.rejected, (state, action) => {
        state.status = STATUS_FAILED
        console.log('STATUS WAKEUP - FAILED')

        enqueueSnackbar(action.payload.response.errMsg, { variant:TOAST_SUCCESS })

      })
      .addCase(wakeUp.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED
        console.log('STATUS WAKEUP - SUCCEEDED')

        const { user, sessionId, roles } = action.payload

        state.loggedIn = !!user;
        state.incarnated = false;
        state.userInfo = action.payload;
        // update AUTH state for legacy
        AuthStore.loginRestored(action.payload)

        // let userData = res.data.user
        user.incarnated = false;
        localStorage.setItem("token", sessionId);
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('roles', JSON.stringify(roles));

        enqueueSnackbar('wake up successfully', { variant:TOAST_SUCCESS });

      })
      .addCase(restorePassword.rejected, (state, action) => {
        state.status = STATUS_FAILED
        enqueueSnackbar(action.payload.response.errMsg, { variant:TOAST_ERROR })

      })
      .addCase(restorePassword.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED
        enqueueSnackbar('Your password was successfully changed.', { variant:TOAST_SUCCESS });
      })
      // ------ STATUS ----
      .addCase(authStatus.fulfilled, (state, action) => {
        state.loggedIn = !!action.payload.user;

        if (!!action.payload.user) {
          const { roles, user, sessionId } = action.payload
          state.userInfo = { roles, user, sessionId }
        } else {
          state.loggedIn = false;
          state.userInfo = null;
          state.incarnated = false;
          localStorage.removeItem('token');
        }

      })
      .addCase(requestNewPassword.rejected, (state, action) => {
        state.status = 'passRequestError';
        enqueueSnackbar(action.payload.response.errMsg, { variant: TOAST_ERROR });
      })
      .addCase(requestNewPassword.fulfilled, (state, action) => {
        state.status = 'passRequestSucceed';
      })
      // ---- GOOGLE LOG IN -----
      .addCase(loginGoogle.pending, (state, action) => {
        state.status = STATUS_LOADING
        console.log('STATUS loginGoogle - LOADING')
      })
      .addCase(loginGoogle.rejected, (state, action) => {
        state.status = STATUS_FAILED
        console.log('STATUS loginGoogle - FAILED');
        enqueueSnackbar(action.payload.response.errMsg, { variant:TOAST_ERROR })

      })
      .addCase(loginGoogle.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED
        console.log('STATUS loginGoogle - SUCCEEDED')
        const { user, sessionId, roles } = action.payload

        state.loggedIn = !!user;
        state.userInfo = action.payload;
        // update AUTH state for legacy
        AuthStore.loginRestored(action.payload)

        localStorage.setItem('token', sessionId);
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('roles', JSON.stringify(roles));
        // SET AUTH TOKEN FOR LEGACY
        SteelCompass.setAuthToken(sessionId);

        enqueueSnackbar('Log in successful.', { variant:TOAST_SUCCESS });
      })
  }
})
export const { setLoggedIn, setIsIncarnated } = authSlice.actions


export default authSlice.reducer

// ----------------- Selectors -----------------------------

export const selectAuthState = state => state.auth;
export const selectAuthStatus = state => state.auth.status;
export const selectGuestInfoStatus = state => state.auth.guesInfoStatus;
export const selectGuestInfo = state => state.auth.guesInfo;
export const selectIsLoggedIn = state => state.auth.loggedIn;
export const selectIsIncarnated = state => state.auth.incarnated;
export const selectAuthToken = state => state.auth?.userInfo?.sessionId || localStorage.getItem('token');
export const selectMyStore = state => state.auth.userInfo?.user.username;
export const selectUserRole = state => state.auth.userInfo?.roles[0];
export const selectUserName = state => state.auth.userInfo?.user.displayName;
export const selectUserEmail = state => state.auth.userInfo?.user.email.toLowerCase();
export const selectUserId = state => state.auth.userInfo?.user.id;
export const selectUserInfo = state => state.auth.userInfo?.user;
export const selectLanguage = state => state.auth.userInfo?.user.locale;
export const selectHasBetaAccess = state => state.auth.userInfo?.user.hasBetaAccess;
export const selectIsAdmin = state => state.auth.userInfo?.roles[0] === 'dark-admin' || state.auth.userInfo?.roles[0] === 'assistant'

export const selectLocalization = state => {
  const locale = state.auth.userInfo?.user.locale.slice(0, 2)
  const role = state.auth.userInfo?.roles[0]
  if (role === 'assistant' && locale === 'en') return 'en-gb'
  else return state.auth.userInfo?.user.locale.slice(0, 2)
};
export const selectUser = state => state.auth?.userInfo?.user;

export const selectHasRequiredLevel = (state, requiredLevel) => {
  const role = state.auth.userInfo?.roles[0]
  switch (requiredLevel) {
    case 'admin':
      return ['assistant', 'dark-admin'].includes(role)
    case 'owner':
      return ['assistant', 'dark-admin', 'owner'].includes(role)
    case 'teacher':
      return ['assistant', 'dark-admin', 'owner', 'teacher'].includes(role)
    case 'accountant':
      return ['assistant', 'dark-admin', 'owner', 'accountant'].includes(role)
    case 'editor':
      return ['assistant', 'dark-admin', 'owner', 'editor'].includes(role)

    default:
      break;
  }
};
