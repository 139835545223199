import React from 'react';
import { theme } from '../../../../ui';
import styled from 'styled-components';
import { drawerWidth } from '../../../../utils/constants';
import { media } from '../../../../utils/media';
import NotesContent from './NotesContent';

const Wrapper = styled.div`
  width:calc(100% - ${({$fullWidth})=> $fullWidth ? '46' : drawerWidth}px);
  background-color: #00000094;
  position: fixed;
  right: 0;
  top: ${theme.headerSize};
  height: calc(100% - ${theme.headerSize} *2);

  align-items: flex-end;
  padding: 10px;
  z-index:999;


  display: ${({$makeItOpen})=> $makeItOpen ? 'flex' : 'none'};
  
  ${media.greaterThan('bigTablet')} {
    display:none;
  }

  ${media.lessThan('tablet')} {
    width:100%;
  }
`


const NotesMobile = ({makeItOpen, fullWidth, setNotesDrawerOpen}) => {

  return (
    <Wrapper $makeItOpen={makeItOpen} $fullWidth={fullWidth} >
      <NotesContent />
    </Wrapper>

  );
}

export default NotesMobile;
