import React from 'react'
import styled from 'styled-components'
import {
  // ShoppingCartOutlined,
  Check,
  CardGiftcardOutlined,
  Face,
  DeleteOutline,
  Add,
  Close,
  Save,
  Storefront,
  Settings,
  Compare,
  LocalMall,
  Foundation,
  ToggleOn,
  Fingerprint,
  Home,
  MeetingRoom,
  SupportAgent,
  Send,
  MenuBook,
  PersonAdd,
  Engineering,
  Insights,
  RestorePage,
  Edit,
  NotificationImportant,
  SpeakerNotes,
  PermMedia,
  AssignmentTurnedIn,
  Groups,
  Quiz,
  EditOff,
  SelfImprovement,
  Search,
  Label,
  AccessTime,
  Email,
  Link,
  ArrowBack,
  Folder,
  MoreHoriz,
  WorkspacePremium,
  ManageAccounts,
  Download,
  Info,
  BoltOutlined,
  Lock,
  LocalOffer,
  ReceiptLong,
  VideoFile,
  AudioFile,
  AddPhotoAlternate,
  GroupAdd,
  GridView,
  VisibilityOff,
  Visibility,
  RocketLaunch,
  ContentCopy,
  Unpublished,
  AdminPanelSettings,
  DesignServices,
  PostAdd,
  Assistant,
  Calculate,
  HistoryEdu,
  Science,
  LocalPolice,
  IosShare,
  Done,
  People,
  Person,
  Cancel,
  School,
  Google,
  Facebook,
  ModeComment,
  AttachFile,
  SmsFailed,
  Textsms,
  NavigateNext,
  InsertDriveFile,
  CreateNewFolder,
  Upload,
  Archive,
  Unarchive,
  PlayCircle,
  Sync,
  DangerousOutlined,
  CheckCircleOutlineOutlined,
  MapsUgcOutlined,
  DoNotDisturbAltOutlined,
  Reply,
  Devices,
  CalendarMonthOutlined,
  PictureAsPdf,
  List,
  Menu,
  ViewModule,
  Image,
  EditNote,
  Favorite,
  FavoriteBorder,
  KeyboardArrowRight,
  KeyboardArrowLeft,
  KeyboardArrowDown,
  KeyboardArrowUp,
  ContactSupport,
  KeyboardDoubleArrowRight,
  KeyboardDoubleArrowLeft
} from '@mui/icons-material';

const sizes = { 'x-small': '16px', 'small': '22px', 'medium': '28px', 'big': '42px', 'xxl': '100px' }

const SvgWrapper = styled.i`
  display: inline-flex;
  height:${({ size }) => sizes[size] || sizes.medium};
  align-self:center;
  &:hover {
    border-radius: 50%;
    background-color:${(props) => props.$hoverEffectColor };
  }
  
  svg {
    width: ${({ size }) => sizes[size] || sizes.medium};
    height: ${({ size }) => sizes[size] || sizes.medium};
    fill: ${(props) => props.color || 'currentColor'};
  }
`
const wrapIcon = (Component) => props =>
  <SvgWrapper {...props}>
    <Component />
  </SvgWrapper>

const Icon = {
  Check: wrapIcon(Check),
  Gift: wrapIcon(CardGiftcardOutlined),
  Cart: wrapIcon(LocalMall),
  Person: wrapIcon(Face),
  Delete: wrapIcon(DeleteOutline),
  Add: wrapIcon(Add),
  Close: wrapIcon(Close),
  Save: wrapIcon(Save),
  Storefront: wrapIcon(Storefront),
  Settings: wrapIcon(Settings),
  Compare: wrapIcon(Compare),
  Cancel: wrapIcon(Cancel),
  Restore: wrapIcon(RestorePage),
  EditStore: wrapIcon(Foundation),
  ToggleOn: wrapIcon(ToggleOn),
  Fingerprint: wrapIcon(Fingerprint),
  Home: wrapIcon(Home),
  Logout: wrapIcon(MeetingRoom),
  Support: wrapIcon(SupportAgent),
  Send: wrapIcon(Send),
  Users: wrapIcon(People),
  Course: wrapIcon(MenuBook),
  Invite: wrapIcon(PersonAdd),
  Create: wrapIcon(Engineering),
  Insights: wrapIcon(Insights),
  Edit: wrapIcon(Edit),
  Notifications: wrapIcon(NotificationImportant),
  Notes: wrapIcon(SpeakerNotes),
  Media: wrapIcon(PermMedia),
  Task: wrapIcon(AssignmentTurnedIn),
  Groups: wrapIcon(Groups),
  Quiz: wrapIcon(Quiz),
  EditOff: wrapIcon(EditOff),
  Yoga: wrapIcon(SelfImprovement),
  Search: wrapIcon(Search),
  Label: wrapIcon(Label),
  Time: wrapIcon(AccessTime),
  Email: wrapIcon(Email),
  Link: wrapIcon(Link),
  Back: wrapIcon(ArrowBack),
  Folder: wrapIcon(Folder),
  CreateFolder: wrapIcon(CreateNewFolder),
  More: wrapIcon(MoreHoriz),
  Certificate: wrapIcon(WorkspacePremium),
  Profile: wrapIcon(ManageAccounts),
  Download: wrapIcon(Download),
  Info: wrapIcon(Info),
  Coupon: wrapIcon(LocalOffer),
  Bolt: wrapIcon(BoltOutlined),
  Lock: wrapIcon(Lock),
  ExpandMore: wrapIcon(KeyboardArrowDown),
  ExpandLess: wrapIcon(KeyboardArrowUp),
  ArrowUp: wrapIcon(KeyboardArrowUp),
  ArrowDown: wrapIcon(KeyboardArrowDown),
  ArrowLeft: wrapIcon(KeyboardArrowLeft),
  ArrowRight: wrapIcon(KeyboardArrowRight),
  Receipt: wrapIcon(ReceiptLong),
  VideoFile: wrapIcon(VideoFile),
  AudioFile: wrapIcon(AudioFile),
  File: wrapIcon(InsertDriveFile),
  AddToLibrary: wrapIcon(AddPhotoAlternate),
  AddGroup: wrapIcon(GroupAdd),
  Package: wrapIcon(GridView),
  VisibleOff: wrapIcon(VisibilityOff),
  Visible: wrapIcon(Visibility),
  Published: wrapIcon(RocketLaunch),
  ByInvitation: wrapIcon(Email),
  InConstruction: wrapIcon(Engineering),
  Copy: wrapIcon(ContentCopy),
  Valid: wrapIcon(CheckCircleOutlineOutlined),
  NotValid: wrapIcon(Unpublished),
  Admin: wrapIcon(AdminPanelSettings),
  CreateEdit: wrapIcon(DesignServices),
  AddCourse: wrapIcon(PostAdd),
  Assistant: wrapIcon(Assistant),
  Accountant: wrapIcon(Calculate),
  Teacher: wrapIcon(HistoryEdu),
  Lab: wrapIcon(Science),
  Owner: wrapIcon(LocalPolice),
  Export: wrapIcon(IosShare),
  Done: wrapIcon(Done),
  User: wrapIcon(Person),
  Student: wrapIcon(School),
  Google: wrapIcon(Google),
  Facebook: wrapIcon(Facebook),
  AnswerUnread: wrapIcon(MapsUgcOutlined),
  AnswerFailed: wrapIcon(SmsFailed),
  AnswerEvaluating: wrapIcon(Textsms),
  Feedback: wrapIcon(ModeComment),
  AttachFile: wrapIcon(AttachFile),
  Next: wrapIcon(NavigateNext),
  Upload: wrapIcon(Upload),
  Archive: wrapIcon(Archive),
  Unarchive: wrapIcon(Unarchive),
  Play: wrapIcon(PlayCircle),
  Sync: wrapIcon(Sync),
  Wrong: wrapIcon(DangerousOutlined),
  DoNotDisturb: wrapIcon(DoNotDisturbAltOutlined),
  Reply: wrapIcon(Reply),
  Devices: wrapIcon(Devices),
  Calendar: wrapIcon(CalendarMonthOutlined),
  PDF: wrapIcon(PictureAsPdf),
  List: wrapIcon(List),
  Menu: wrapIcon(Menu),
  ViewModule: wrapIcon(ViewModule),
  Image: wrapIcon(Image),
  EditNote:wrapIcon(EditNote),
  Following:wrapIcon(Favorite),
  NotFollowing:wrapIcon(FavoriteBorder),
  Questions:wrapIcon(ContactSupport),
  DoubleArrowLeft:wrapIcon(KeyboardDoubleArrowLeft),
  DoubleArrowRight:wrapIcon(KeyboardDoubleArrowRight),
};

Icon.displayName = 'Icon';

export default Icon;
