import React from 'react';
import styled from 'styled-components';
// import { useTranslation } from 'react-i18next';
import { Typo} from '../../../../ui';

const Wrapper = styled.div`
  width:100%;
`

const NoAccessPage = () => {
  // const { t } = useTranslation();
  
  return (
    <Wrapper>
      <Typo.ContentSmallHeader $textAlign='left'>You dont have access to this page</Typo.ContentSmallHeader>
      <Typo.Text >Contact support if you think you should be able to see the content of this page</Typo.Text>
      <Typo.Text >support@coursio.com</Typo.Text>
    </Wrapper>
  );
};

export default NoAccessPage;
