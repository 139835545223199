import { createSlice, createAsyncThunk, createEntityAdapter} from '@reduxjs/toolkit'
import { STATUS_FAILED, STATUS_LOADING, STATUS_SUCCEEDED, TASK_TYPE, TOAST_ERROR, TOAST_SUCCESS } from '../../utils/constants';
import { taskApi, taskResultsApi } from '../../utils/urls';
import { enqueueSnackbar } from 'notistack'
import httpClient from "../../services/httpClient";

// ----------------- Thunks -----------------------------
export const fetchTasks = createAsyncThunk('tasks/fetchTasks', async ({ courseId, userId }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {
      courseId,
    },
    method: 'read'
  };

  const res = await httpClient.post(taskApi(), body, { getState, dispatch, rejectWithValue });

  return res.data;
})

export const saveTaskFeedback = createAsyncThunk('tasks/saveTaskFeedback', async ({ feedbackData, courseId }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: { ...feedbackData },
    method: 'assess'
  };

  const res = await httpClient.post(taskResultsApi(), body, { getState, dispatch, rejectWithValue });

  const message = res.isError ? res.errMsg : 'Task feedback saved successfully'
  const variant = res.isError ? TOAST_ERROR : TOAST_SUCCESS

  !res.isError && dispatch(fetchTasks({ courseId }))
  enqueueSnackbar(message, { variant })
  return res.data;
})

export const answerOnTask = createAsyncThunk('tasks/answer', async ({ pmid, taskId, value, isQuiz }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {
      body: { value },
      pmid,
      taskId
    },
    method: 'answer'
  };

  const res = await httpClient.post(taskApi(), body, { getState, dispatch, rejectWithValue });

  return res.data;
})

// ----------------- Reducers -----------------------------

const tasksUsersAdapter = createEntityAdapter()
const tasksAdapter = createEntityAdapter()

const initialState = {
  status: 'idle',
  entities: {},
  ids: [],
  taskUserCounts: [],
  users: {
    entities: {},
    ids: [],
  },
  questions: [],
}

const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    reset: () => initialState
  },
  extraReducers: builder => {
    builder
      .addCase(fetchTasks.pending, (state, action) => {
        state.status = STATUS_LOADING
        tasksUsersAdapter.setAll(state.users, [])
      })
      .addCase(fetchTasks.rejected, (state, action) => {
        state.status = STATUS_FAILED
        tasksAdapter.setAll(state, [])
        tasksUsersAdapter.setAll(state.users, [])
      })
      .addCase(fetchTasks.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        const tasks = []
        let taskUserCounts = []
        let questions = []
        let users = []
        for (const [id, task] of Object.entries(action.payload)) {
          let usersResults = {}
          let allLastAnswerGrades = {}
          let lastUpdate = {}
          task.taskResults.map((result) => {
            const user = result.user
            lastUpdate = { type: 'response', date: result.created }
            let grade = result.feedback.grade
            if (result.feedback?.created) lastUpdate = { type: 'feedback', date: result.feedback.created }
            let value = result.body.value
            if (task.type !== TASK_TYPE.OPEN && task.type !== TASK_TYPE.FILE) {
              grade = value.toString() === task.correctResponse.value.toString() ? 1 : 0
              value = result.body.value.map((item) => {
                return task.caption.items[item]
              })
            }

            allLastAnswerGrades = { ...allLastAnswerGrades, [user.id]: grade !== undefined ? grade : 99 }

            return usersResults = {
              ...usersResults,
              [user.id]: {
                name: user.name,
                id: user.id,
                taskTitle: task.caption,
                avatar: user.avatarUrl,
                taskType: task.type,
                taskId: id,
                lastAnswerGrade: grade,
                hasBeenApproved: allLastAnswerGrades[user.id],
                lastUpdate: lastUpdate,
                answers: {
                  ...usersResults[user.id]?.answers,
                  [result.id]: {
                    id: result.id,
                    points: result.points,
                    value: value,
                    grade: grade, // 1 === correct/approved
                    //only for open questions
                    files: result.bodyFiles,
                    created: result.created,
                    feedback: { ...result.feedback, files: result.feedbackFiles },
                  }
                }
              }
            }
          })

          const results = Object.values(usersResults)
          taskUserCounts.push(results.length)
          questions.push({ ...task.caption, taskType: task.type, taskId: task.id })
          users.push(results)
          tasks.push({
            id: +id,
            type: task.type,
            pageId: task.pageId,
            sectionId: task.sectionId,
            maxPoints: task.maxPoints,
            createdBy: task.createdBy,
            courseId: task.courseId,
            correctOptions: task.correctResponse?.value,
            question: task.caption.main,
            comment: task.caption.comment,
            options: { ...task.caption.items },
            usersCount: results.length,
            users: results,
            needsAttention: Object.values(allLastAnswerGrades).filter((item) => item === 99),
            incorrect: Object.values(allLastAnswerGrades).filter((item) => item === 0),
          })
        }
        state.taskUserCounts = taskUserCounts
        state.questions = questions
        // questionsAdapter.setAll(state.questions, questions)
        tasksAdapter.setAll(state, tasks)

        const usersFlated = users.flat()
        let alreadyChecked = {}
        for (const user of usersFlated) {
          const alreadyExistsUser = alreadyChecked[user.id]
          if (!alreadyExistsUser) {
            const newUser = {
              name: user.name,
              id: user.id,
              avatar: user.avatar,
              tasks: {
                [user.taskId]: {
                  lastAnswerGrade: user.lastAnswerGrade,
                  hasBeenApproved: user.hasBeenApproved,
                  id: user.taskId,
                  lastUpdate: user.lastUpdate,
                  type: user.taskType,
                  answers: { ...user.answers }
                }
              }
            }
            tasksUsersAdapter.addOne(state.users, newUser)
            alreadyChecked[user.id] = newUser
          }
          else {
            const newUser = {
              ...alreadyExistsUser,
              tasks: {
                ...alreadyExistsUser.tasks,
                [user.taskId]: {
                  lastAnswerGrade: user.lastAnswerGrade,
                  hasBeenApproved: user.hasBeenApproved,
                  id: user.taskId,
                  lastUpdate: user.lastUpdate,
                  type: user.taskType,
                  title: user.taskTitle,
                  answers: { ...user.answers }
                }
              }
            }
            alreadyChecked[user.id] = newUser

            tasksUsersAdapter.upsertOne(state.users, newUser)
          }
        }
      })
      .addCase(answerOnTask.pending, (state, action) => {
        state.status = STATUS_LOADING
      })
      .addCase(answerOnTask.rejected, (state, action) => {
        state.status = STATUS_FAILED
        enqueueSnackbar(action.payload.response.errMsg, { variant:TOAST_ERROR })
      })
      .addCase(answerOnTask.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        !action.meta.arg.isQuiz && enqueueSnackbar('Task answer submited', { variant:TOAST_SUCCESS });
      })
  }
})

export const { reset } = tasksSlice.actions
export default tasksSlice.reducer

// ----------------- Selectors -----------------------------
export const {
  selectAll: selectTasks,
  selectById: selectTaskById,
} = tasksAdapter.getSelectors((state) => state.tasks)

export const {
  selectAll: selectTaskUsers,
  selectById: selectTaskUserById,
} = tasksUsersAdapter.getSelectors((state) => state.tasks.users)

export const selectStatus = state => state.tasks.status;
export const selectTaskUserCounts = state => state.tasks.taskUserCounts;
export const selectQuestions = state => state.tasks.questions;
